/*
 * @Description: 通用站点地图
 * @Autor: lichao
 * @Date: 2021-11-26 09:54:59
 * @LastEditors: lichao
 * @LastEditTime: 2021-11-29 09:15:39
 */
import "./index.less";

const SiteMapFooter = () => {
  let isUrl = window.location.href.includes('www.bjftcf.com');
  return (
    <div className="siteMapFooter">
      <div className="info">
        <div className="w1400" >
          <img src="/image/logoMap.png" alt="" />
          <ul>
            <li>
              <img src="/image/position_icon.png" alt="" />
              <span>国际会议中心：北京市房山区北京基金小镇国际会议中心</span>
            </li>
            <li>
              <img src="/image/24gl-telephone2.png" alt="" />
              <span>注册咨询：010-56860966、13911822501</span>
            </li>
            <li>
              <img src="/image/position_icon.png" alt="" />
              <span>政务服务中心：北京市房山区良乡民丰东街6号</span>
            </li>
            <li>
              <img src="/image/24gl-phoneTime.png" alt="" />
              <span>工作时间：周一至周五 （09:00-12:00 13:30-17:30）</span>
            </li>
          </ul>
          <img src="/image/QRcode.png" alt="" />
          <div className="codeStyle">
            <img src="/image/entry_or_code.png" alt=""/>
            <span>在线咨询</span>
          </div>
        </div>
      </div>
      <div className="code">
        <div className="w1200">
             {
               isUrl ?
                 <p>
                   互联网违法和不良信息投诉：010-56860668 COPYRIGHT © 2015-2024
                   北京基金小镇 京ICP备2024059839号-1
                 </p>
                 :
                 <p>
                   互联网违法和不良信息投诉：010-56860668 COPYRIGHT © 2015-2024
                   北京基金小镇 京ICP备16008758号-1
                 </p>
             }
            <p>
              增值电信业务经营许可证：京B2-20202691
              {
                isUrl?
                  <span>京公网安备：11011102002628号</span>
                  :
                  <span>京公网安备：11011102001195号</span>
              }
            </p>
          </div>

      </div>
    </div>
  );
};

export default SiteMapFooter;
